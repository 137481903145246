/* eslint-disable react/prop-types */
import SvgIcon from '@material-ui/icons/Clear';
import { Button, Datagrid, Filter, List, Pagination, SaveButton, TextInput } from 'ra-ui-materialui';
import * as React from 'react';
import { CreateButton, ExportButton, TopToolbar, useNotify, useRecordContext, useRedirect, useResourceContext, useResourceDefinitionContext } from 'react-admin';
import { BQIcons } from '../../symbols';
import { useBQStyles } from '../../themes'
import { bqAuthData } from '../../utils/bq-auth';
import { getDateTime, replacePlaceholders } from '../../utils/textUtils';
import { ConditionField, FunctionField } from './bq-form-components';
import DraggableDialog from './ConfirmationDialog';
import { SoftDeleteListButton } from './SoftDeleteListButton';
import { cloneChild } from './UITools';
import { CircularProgress, LinearProgress } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { useFormContext } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux';
import { getFromCache } from '../../utils/globals';

export const getBasePath = () => `/${getResourceName()}`
export const getRootLocation = () => window.location.href.substring(0, window.location.href.indexOf('#') + 1)

const getResourceName = () => {
    const location = window.location
    return location.pathname.split('/')[1]
}

const setSubjectOrPatient = (value) => value?.replace(/\[subjectOrPatient\]/gi, bqAuthData.subjectOrPatient)

export const BQProgress = ({ type }) => <div style={{ textAlign: 'center' }}>{type === 'linear' ? <CircularProgress /> : <LinearProgress />}</div>

export const BQToolbar = (props) => {
    const record = useRecordContext()
    const redirect = useRedirect()
    const { id, type, label, labelSubmit, nameField, content, confirmation, hideButtons, hideBackButton, exporter, exporterLabel, onBack, showSendButton, forceExporter } = props
    const basePath = getBasePath()
    const notify = useNotify()

    const classes = getFromCache('bqClasses')
    const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState();
    const [dialogContent, setDialogContent] = React.useState({});
    const [confirmed, setConfirmed] = React.useState(false);
    const [enableSave, setEnableSave] = React.useState(true)

    const { dialogTitle, dialogText, dialogQuestionIndex } = dialogContent

    const goBack = (e) => {
        if (onBack) {
            onBack(e)
        } else {
            e.stopPropagation();
            e.preventDefault();
            redirect(basePath)
        }
    }

    const formContext = useFormContext()
    const bqForm = formContext?.getValues()

    const confirm = (confirmationIndex, e) => {
        setConfirmationDialogOpen(false)
        const currentConfirmation = confirmation?.[confirmationIndex]
        if (currentConfirmation) {
            const askForConfirmationResult = currentConfirmation?.condition(bqForm, record)
            if (askForConfirmationResult) {
                const { title, text } = confirmation[confirmationIndex]

                setDialogContent({
                    dialogTitle: replacePlaceholders(
                        setSubjectOrPatient(title),
                        bqForm,
                        askForConfirmationResult),
                    dialogText: replacePlaceholders(
                        setSubjectOrPatient(text),
                        bqForm,
                        askForConfirmationResult),
                    dialogQuestionIndex: confirmationIndex
                })
                setConfirmationDialogOpen(true)
            } else {
                confirm(confirmationIndex + 1)
            }
        } else {
            setConfirmed(true)
            setTimeout(() => {
                setEnableSave(true)
                setTimeout(() => document.getElementById('save_button').click(), 100)
            }, 100)
        }
    }
    const toolbarTitle = label || record?.[`${nameField || 'name'}`] || `New ${type}`

    const submitBQForm = (e, send) => {
        if (!enableSave) {
            e.stopPropagation()
            e.preventDefault()
            return
        }
        setEnableSave(false)
        if (send) {
            formContext.setValue('send', true)
        }
        if (!confirmed) {
            e.stopPropagation()
            e.preventDefault()
            formContext.trigger().then(isFormValid => {
                if (isFormValid) {
                    confirm(0, e)
                } else {
                    setEnableSave(true)
                    notify(
                        'The form is not valid. Please check for errors.',
                        { type: 'error' }
                    )
                }
            })
        }
    }

    return (
        <>
            <div className={classes.toolbarContainer}>
                <table className={classes.toolbarFormButtonsContainer}>
                    <tr>
                        <td className={classes.toolbarMinimumCell}>
                            {
                                !hideBackButton && <div className={classes.backButton} onClick={(e) => goBack(e)}>
                                    <SvgIcon id="back_button" component={BQIcons.iconBack} viewBox="0 0 24 24" />
                                </div>
                            }
                        </td>
                        <td className={classes.toolbarContentCell}>
                            <table>
                                <tr>
                                    <td id="page_title">
                                        {toolbarTitle}
                                        {id && <div
                                            id="btn_copy_id"
                                            className={classes.copyButton}
                                            onClick={() => {
                                                navigator.permissions.query({ name: 'clipboard-write' }).then(permissionStatus => {
                                                    if (permissionStatus.state === 'granted' || permissionStatus.state === 'prompt') {
                                                        navigator.clipboard.writeText(id).then(() => {
                                                            alert('ID copied to clipboard')
                                                        }).catch(err => {
                                                            console.error('Failed to copy text: ', err);
                                                        });
                                                    } else {
                                                        console.error('Clipboard permission denied');
                                                    }
                                                });
                                            }}
                                        >
                                        </div>}
                                        <span style={{ color: 'white' }}>{id}</span>
                                    </td>
                                    <td>{content}</td>
                                </tr>
                            </table>
                        </td>
                        <td className={classes.toolbarMinimumCell}>
                            <table className={classes.toolbarFormButtonsContainer}>
                                <tr>
                                    {!hideButtons && <td>
                                        <Button id="cancel_button" {...props} variant='outlined' label="Cancel" onClick={(e) => goBack(e)} />
                                    </td>}
                                    {!hideButtons && <td>
                                        {showSendButton && <SaveButton
                                            color="primary"
                                            variant='outlined'
                                            id="save_button"
                                            alwaysEnable
                                            label={labelSubmit || 'Save'}
                                            submitOnEnter={false}
                                            onClick={e => submitBQForm(e)} />}
                                        {!showSendButton && <SaveButton
                                            id="save_button"
                                            alwaysEnable
                                            label={labelSubmit || 'Save'}
                                            submitOnEnter={false}
                                            onClick={e => submitBQForm(e)}
                                        />}

                                    </td>}
                                    {showSendButton && <SaveButton
                                        id="send_button"
                                        alwaysEnable
                                        label="Send"
                                        submitOnEnter={false}
                                        onClick={e => submitBQForm(e, true)}
                                    />}
                                    {(record?.id || forceExporter) && exporter && <td><Button id="export_button" variant='outlined' label={exporterLabel || `Export List`} onClick={() => exporter(formContext)} style={{ minWidth: '160px' }} /></td>}
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </div>

            {confirmation?.[dialogQuestionIndex] && <DraggableDialog
                open={confirmationDialogOpen}
                dialogIndex={dialogQuestionIndex}
                handleClose={(e) => {
                    e.stopPropagation();
                    setConfirmationDialogOpen(false)
                }}
                title={dialogTitle}
                content={dialogText}
                onConfirm={(e) => {
                    e.stopPropagation()
                    confirm(dialogQuestionIndex + 1)
                }}
                confirmText="Yes, I'm sure"
            />}
        </>
    )
}


export const BQFilter = (props) => {
    const resource = getResourceName()
    const { nameField, ...rest } = props
    const queryNameField = nameField || 'name'
    const resourceName = resource[0].toUpperCase() + resource.substring(1);
    const listQuery = `${resourceName[0].toLowerCase() + resourceName.substring(1)}By${queryNameField[0].toUpperCase() + queryNameField.substring(1)}.filter`

    return (<Filter {...rest}>
        <TextInput id="filter_input" placeholder=' ' autoComplete={null} variant="outlined" source={listQuery} label={''} alwaysOn resettable />
    </Filter>)
}

export const BQPagination = props => (
    <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} {...props} />
);

export const BQListToolbar = (props) => {
    const { exporter, hideAddItem, hideExport, children, appendButtons } = props
    const { isSuperAdmin, isViewer } = bqAuthData
    const resourceContext = useResourceContext()
    const resource = useResourceDefinitionContext()?.definitions?.[resourceContext]
    if (!resource) {
        return <></>
    }
    let objectName = resource?.options?.label || resource?.name
    objectName = objectName.endsWith('ies') ? `${objectName.substring(0, objectName.length - 3)}y` : objectName
    objectName = objectName.endsWith('s') ? objectName.substring(0, objectName.length - 1) : objectName
    let newItemButtonLabel = `Add ${objectName}`
    switch (objectName) {
        case 'Text Message':
            newItemButtonLabel = 'New Text Message'
            break;
    }
    return (<TopToolbar>
        {!appendButtons && children}
        {!hideAddItem && ((bqAuthData.isAdmin && objectName !== 'Device') || bqAuthData.isSuperAdmin || (bqAuthData.isOperator && objectName === 'Text Message')) && <CreateButton id="add_item" label={newItemButtonLabel} style={{ width: 'auto', }} />}
        {!hideExport && (isSuperAdmin || isViewer) && <ExportButton id="export_list" label="Export list" style={{ width: 'auto', }} {...(exporter && { exporter } || {})} />}
        {appendButtons && children}
    </TopToolbar>)
}

export const BQModelList = (props) => {
    const classes = getFromCache('bqClasses')
    const language = useSelector(state => state.langSelected.language);
    const redirect = useRedirect()
    const { nameField, disableDelete, deleteIconLabel, showDates, disableEdit, expand, exporter, dataGridRef, hideTime, customButton, children } = props
    const basePath = getBasePath()
    const label = !disableDelete || deleteIconLabel ? (deleteIconLabel || 'Delete') : ''

    const [display, setDisplay] = React.useState(language)

    React.useEffect(() => {
        setDisplay(false)
    }, [language])

    React.useEffect(() => {
        if (!display) {
            setDisplay(true)
        }
    }, [display])

    return (
        <List
            id={`${props.nameField}_list`}
            {...props}
            perPage="50"
            pagination={<BQPagination />}
            filters={<BQFilter {...props} />}
            actions={<BQListToolbar exporter={exporter} />}
        >
            <Datagrid
                bulkActionButtons={false}
                rowClick={(e) => {
                    redirect(`${basePath}/${e}`)
                }}
                ref={dataGridRef}
                expandSingle={true}
                expand={expand}
            >
                {
                    display && children
                }
                {showDates && <FunctionField id="createdAt" label="Created" source="createdAt" value={v => getDateTime(v, hideTime)} />}
                {showDates && <FunctionField id="updatedAt" label="Last updated" source="updatedAt" value={v => getDateTime(v, hideTime)} />}
                {customButton && <div className={customButton.className} title={customButton.title} ></div>}
                <ConditionField hideIf={disableEdit} style={{ maxWith: '64px' }}>
                    <div className={classes.editButton} title={'Edit'} ></div>
                </ConditionField>
                <ConditionField label={label} hideIf={disableDelete} style={{ maxWith: '64px' }}>
                    <SoftDeleteListButton id="delete_button" nameField={nameField || 'name'} title={deleteIconLabel} />
                </ConditionField>
            </Datagrid>
        </List>
    )
}

export const BQSection = (props) => {
    const { title, readOnly, style, language, headerPadding, visible, fullWidth, component } = props
    const { code: langCode, name: langName } = language || {}
    const isEnglish = !langCode || langCode.toLowerCase() === 'en'
    const classes = getFromCache('bqClasses')

    const propsToCopy = { ...props }
    delete propsToCopy.children

    const children = React.Children.map(props.children, child => {
        return cloneChild(child, propsToCopy, readOnly)
    })

    const uniqueId = title?.trim().replace(/\s/g, '_')
    return !props.hasOwnProperty('visible') || visible ? (
        <div className={classes.BQSectionContainer} style={{ ...(fullWidth ? { minWidth: '100%' } : {}), paddingBottom: '32px', ...style }}>
            <table className={classes.BQSectionHeader} style={{ width: `100%` }}>
                <tr>
                    <td>
                        <div id={`BQSection_${uniqueId}`} style={{ ...(headerPadding !== undefined ? { paddingTop: headerPadding } : {}) }}>{`${(title || 'Title').toUpperCase()} ${isEnglish ? '' : ' - ' + langName}`}</div>
                    </td>
                    <td style={{ textAlign: 'right' }}>
                        <span id={`BQSection_extraComponent_${uniqueId}`} style={{ textAlign: 'left' }}>{component}</span>
                    </td>
                </tr>
            </table>

            <table id={`BQSection_content_${uniqueId}`} className={classes.BQSectionContent}>{children}</table>
        </div >) : <></>
}



export const BQSideBySide = (props) => {
    const classes = getFromCache('bqClasses')
    const columnGap = props.columnGap || 128;
    const children = Array.isArray(props.children) ? props.children : [props.children]

    const { style } = props

    const childrenComponents = children.filter(child => child).map((child, index) => {
        return (<td className={classes.sideBySideTableColumn} style={{
            paddingLeft: `${(index !== 0 ? columnGap / 2 : 0)}px`,
            paddingRight: `${(index !== children.length - 1 || children.length === 1 ? columnGap / 2 : 0)}px`,
            width: `${100 / (children.length || 1)}%`
        }}>
            {React.isValidElement(child) ? React.cloneElement(child) : child}
        </td>)
    })

    return <table className={classes.sideBySideTable} style={style}>
        <tr>
            {childrenComponents}
        </tr>
    </table>
}

export const BQSecondarySection = (props) => (<tr {...props}><td colSpan="2">{props.children}</td></tr>)