import * as React from 'react';
import { Button, Create, Edit, SimpleForm, useNotify, useRecordContext, useRedirect } from "react-admin"
import { BQSimpleForm, CustomList, DefaultValueField } from "../Generic/bq-form-components"
import { BQToolbar } from "../Generic/BQUI"

import { getDateTime } from "../../utils/textUtils"
import BQCRUDBuilder from '../Generic/BQCRUDComponent';
import { useBQGetList, useBQMutation } from '../Generic/hooks';
import { BQFileInput, CustomToolbar, SinglePackageSection } from './inventoryUI';
import { choicesFromDictionary, editorGlobalProps } from '../../utils/constants';
import { prepareFormData } from '../../utils/transforms';
import { bqAuthData } from '../../utils/bq-auth';
import { getFromCache, useGetAppSettings } from '../../utils/globals';
import { createRefreshToken, handleLocationValue, prepareItemInPackage } from '../../utils/inventoryTools';
import { SoftDeleteListButton } from '../Generic/SoftDeleteListButton';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { BQDropDown } from '../Generic/bq-input';
import { validate } from '../../utils/validations';
import { OUSelectInput } from '../organizationUnitSelector.component';
import { record } from 'aws-amplify/analytics';


export const PackageCSVUploader = ({ onSuccess }) => {
    const notify = useNotify()
    const [fileUploadData, setFileUploadData] = React.useState()
    const uploadOperation = useBQMutation({
        mutation: 'uploadPackageCSV',
        variables: fileUploadData
    })

    React.useEffect(() => {
        if (uploadOperation?.data?.uploadPackageCSV?.id) {
            notify('Package CSV uploaded successfully')
            setFileUploadData(null)
            onSuccess?.()
        }
    }, [uploadOperation])

    return <form style={{ display: 'inline-block' }}>
        <BQFileInput
            label=""
            onFileSelected={async (data) => {
                setFileUploadData(data)
            }}
        />
    </form>

}

export const PackageList = ({ onRefresh }) => {
    const { isSuperAdmin, isInventoryAdmin } = bqAuthData
    const isSuperUser = isSuperAdmin || isInventoryAdmin
    const { conditions, itemStatuses } = useGetAppSettings()
    const [refreshToken, setRefreshToken] = React.useState(0)
    const listData = useBQGetList({ resource: 'packages', refreshToken })
    const redirect = useRedirect()

    const refresh = () => {
        setRefreshToken(createRefreshToken())
        setTimeout(() => {
            onRefresh?.()
        }, 500)
    }

    return <CustomList
        {...{
            title: 'Packages',
            maxHeight: 512,
            data: listData?.data,
            sortField: 'updatedAt',
            sortOrder: 'ASC',
            rowClick: (e) => redirect(`/packages/${e}`),
            component: (
                isSuperUser && <>
                    <PackageCSVUploader onSuccess={refresh} />
                    <Button onClick={() => {
                        redirect('/packages/create')
                    }}>Create new package</Button>
                </>
            )
        }}>

        <DefaultValueField source="updatedAt" value={v => getDateTime(new Date(v), true)} />
        <DefaultValueField source="packageSerialNumber" label="Package #" />
        <DefaultValueField source="currentLocation" value={handleLocationValue} />
        <DefaultValueField source="condition" value={(v) => conditions?.find(item => item.id == v)?.name} />
        <DefaultValueField source="status" value={(v) => itemStatuses[v]?.name || v} />
        {isSuperUser && <SoftDeleteListButton
            id="delete_button"
            resoureName="packages"
            nameField={'packageSerialNumber'}
            title="Delete"
            onDelete={refresh}
        />}
    </CustomList>
}

export const PackageUpdate = (props) => {
    const classes = getFromCache('bqClasses');
    const { onItemUpdated } = props
    const notify = useNotify()
    const { itemStatuses, conditions } = useGetAppSettings()
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (e) => {
        setOpen(false);
    };

    const handleSave = (e) => {
        notify('Item updated successfully');
        setOpen(false);
        onItemUpdated?.(e)
    };

    return itemStatuses && (
        <div style={{ textAlign: 'right' }}>
            <div className={classes.editButton} onClick={handleClickOpen} title="Edit status of item"></div>
            <Dialog open={open} maxWidth={1024} onClose={handleClose} aria-labelledby="form-dialog-title"  >
                <DialogTitle id="form-dialog-title" style={{ width: '800px' }}>Edit status of item</DialogTitle>
                <DialogContent>
                    <Edit
                        redirect={false}
                        mutationMode='pessimistic'
                        transform={(data) => {
                            const { id, condition, status, currentLocation } = data
                            return {
                                id,
                                condition,
                                status,
                                currentLocation,
                                forceChangeItemsStatus: true
                            }
                        }}
                        mutationOptions={{
                            onSuccess: handleSave
                        }}
                        resource="packages"
                    >
                        <SimpleForm toolbar={
                            <CustomToolbar onCancel={handleClose} />
                        }>
                            <table style={{ width: '100%' }}>
                                <BQDropDown
                                    source="condition"
                                    label="Condition"
                                    choices={choicesFromDictionary(conditions)}
                                    unsorted
                                    onChange={(data) => {
                                        const { source: itemSource, selection, formContext } = data
                                        formContext.setValue('condition', selection.id)
                                    }}
                                />
                                <BQDropDown
                                    source="status"
                                    label="Item status"
                                    choices={choicesFromDictionary(itemStatuses)}
                                    unsorted
                                    validate={validate}
                                    onChange={(data) => {
                                        const { source: itemSource, selection, formContext } = data
                                        formContext.setValue('status', selection.id)
                                    }}
                                />
                                <OUSelectInput source="currentLocation" useNameAsValue />
                            </table>
                        </SimpleForm>
                    </Edit>
                </DialogContent>
            </Dialog >
        </div >
    )
}

export const PackageEditor = (props) => {
    const { conditions, itemStatuses } = useGetAppSettings()
    const { isSuperAdmin, isInventoryAdmin } = bqAuthData
    const isSuperUser = isSuperAdmin || isInventoryAdmin
    const pack = useRecordContext()
    const redirect = useRedirect()
    const record = useRecordContext()

    const enableItemStatusEdit = isSuperUser || pack?.status !== itemStatuses?.READY_TO_USE
    return !!conditions && <BQSimpleForm>
        <BQToolbar
            id={record?.id}
            label={`${pack ? `Package #${pack.packageSerialNumber}` : ''}`}
            onBack={() => redirect(`/inventories`)}
        />
        {!!pack?.id &&
            <CustomList
                {...{
                    title: 'Package details',
                    noCount: true,
                    data: pack ? [pack] : null,
                    sortField: 'updatedAt',
                    sortOrder: 'ASC',
                }}>
                <DefaultValueField source="packageSerialNumber" label="Serial Number" />
                <DefaultValueField source="currentLocation" label="Current location" value={handleLocationValue} />
                <DefaultValueField source="condition" value={(v) => conditions.find(item => item.id == v)?.name} />
                <DefaultValueField source="status" value={(v) => itemStatuses[v]?.name || v} />
                <DefaultValueField source="updatedAt" label="Last update" value={v => getDateTime(new Date(v), true)} />
                {isSuperUser && <DefaultValueField value={(v, record) => <PackageUpdate />} />}
            </CustomList>}

        <SinglePackageSection {...{
            pack,
            canEditItemStatus: isSuperUser,
            rowClick: e => redirect(`/inventoryItems/${e.field.id}`)
        }} />
        {!!pack?.id &&
            <CustomList
                {...{
                    title: 'Package History',
                    data: pack?.log,
                    sortField: 'timestamp',
                    sortOrder: 'DESC',
                }}>
                <DefaultValueField source="timestamp" value={v => getDateTime(new Date(v), true)} />
                <DefaultValueField source="location" value={handleLocationValue} />
                <DefaultValueField source="status" value={(v) => itemStatuses[v]?.name || v} />
            </CustomList>}
    </BQSimpleForm>
}

const packageTransform = async (data) => {
    delete data.log
    delete data.containerType_Id
    delete data.condition
    delete data.status
    data.items = data.items.map((item, index) => prepareItemInPackage(item, index))

    return prepareFormData(data)
}

const PackageMutation = (isCreate, props) => {
    const mutationProps = { ...props, ...editorGlobalProps(), transform: (data) => packageTransform(data) }
    return (
        <div>
            {isCreate ?
                (<Create {...mutationProps} >
                    <PackageEditor {...props} />
                </Create>)
                :
                (<Edit {...mutationProps}>
                    <PackageEditor {...props} />
                </Edit>)
            }
        </div>
    )
}

const PackageCreate = (props) => PackageMutation(true, props)

const PackageEdit = (props) => PackageMutation(false, props)

export default BQCRUDBuilder({
    Create: PackageCreate,
    Edit: PackageEdit,
    List: null
})