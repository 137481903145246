/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
    TextField,
    List,
    Datagrid,
    useInput,
    DataProviderContext,
    ReferenceField,
    useNotify,
    Button,
    SaveButton,
    useRedirect,
} from 'react-admin';

import { BQPagination, BQSection, BQSideBySide } from './Generic/BQUI';
import { BQJsonField, FunctionField } from './Generic/bq-form-components';
import { bqAuthData } from '../utils/bq-auth';
import { getDateTime } from '../utils/textUtils';
import { BQFilterCheckbox, BQFilterDropDown, BQFilterInput } from './Generic/bq-filter-components';
import BQCRUDBuilder from './Generic/BQCRUDComponent';
import { getFromCache } from '../utils/globals';



const createRefreshToken = () => (parseInt(Math.random() * 100)).toString()

const describeDBChange = (record) => {
    const { tableName } = record
    const objectName = tableName?.substring(0, tableName?.indexOf('-'))
    return `DB change (${objectName})`
}

const describeItemCreated = (record) => {
    const { tableName } = record
    const objectName = tableName?.substring(0, tableName?.indexOf('-'))
    return `Item was created (${objectName})`
}

const getDescriptionForEvent = (record) => {
    const { eventName, description } = record
    let value = description
    if (!value) {
        const parsedEventName = eventName.split(' ')[0]
        switch (parsedEventName) {
            case 'InitiateAuth':
                value = 'Login process started'
                break;
            case 'ForgotPassword':
                value = 'A user request to reset the password'
                break;
            case 'ConfirmForgotPassword':
                value = 'The password of the user was changed'
                break;
            case 'RespondToAuthChallenge':
                value = 'Login success'
                break;
            case 'UpdateCognitoUser':
                value = 'User data updated'
                break;
            case 'UpdateItem':
                value = describeDBChange(record)
                break;
            case 'ResetPasswordForUser':
                value = 'Password reset by Admin'
                break;
            case 'CreateCognitoUser':
                value = 'A user was created'
                break;
            case 'DisableCognitoUser':
                value = 'A user was disabled'
                break;
            case 'CreateItem':
                value = describeItemCreated(record)
                break;
        }
    }
    return value
}

export const AuditTrailList = (props) => {

    const dataProvider = React.useContext(DataProviderContext);
    const [auditTrailEventNameState, setAuditTrailEventNameState] = React.useState({
        isLoading: false
    })

    const [visibleFields, setVisibleFields] = useState({
        description: { visible: true },
        userName: { visible: true, label: 'User email' },
        userId: { visible: false, label: 'User id' },
        eventName: { visible: true },
        eventType: { visible: true },
        tableName: { visible: true },
        affectedUserId: { visible: false, label: 'Affected user id' },
        dataBefore: { visible: false },
        dataAfter: { visible: false },
        dataDiff: { visible: true },
        sourceOfChange: { visible: false },
    })

    useEffect(() => {
        dataProvider.getList('auditTrailEventNames', {
            pagination: { page: 1, perPage: 10000 },
            sort: {},
        }).then(response => {
            setAuditTrailEventNameState({
                eventNames: response.data.map(item => {
                    return { name: item, value: item }
                })
            })
        }).catch((error) => {
            console.error(error)
        })
    }, [])

    return (
        <List
            resource="auditTrails"
            actions={<></>}
            id={`auditTrail_list`}
            perPage="50"
            pagination={<BQPagination />}
            filters={[<BQAuditTrailFilter source="listAuditTrails.filter" label={''} alwaysOn collections={auditTrailEventNameState} />]}
            empty={false}
        >
            <>
                <div style={{ marginTop: '0px' }}>
                    <BQSection title="Columns to display" style={{ maxWidth: '100%' }}>
                        <table>
                            <tr>
                                {Object.keys(visibleFields).map(field => {
                                    const fieldItem = visibleFields[field]
                                    return <td style={{ paddingRight: '32px' }}>
                                        <BQFilterCheckbox
                                            field={field}
                                            label={fieldItem.label}
                                            value={fieldItem.visible}
                                            onClick={(value) => {
                                                fieldItem.visible = value
                                                setVisibleFields({ ...visibleFields })
                                            }} />
                                    </td>
                                })}
                            </tr>
                        </table>
                    </BQSection>
                </div>
                <BQSection title={`Audit Trail Results`} style={{ maxWidth: '100%' }}>
                    <br />
                    <Datagrid bulkActionButtons={false}>
                        <FunctionField source="timestamp" value={(val) => {
                            try {
                                return getDateTime(new Date(parseInt(val)))
                            }
                            catch {
                                return val
                            }
                        }} />
                        {Object.keys(visibleFields).filter(key => visibleFields[key].visible).map(field => {
                            switch (field) {
                                case 'userName':
                                    return (
                                        <ReferenceField
                                            link={false}
                                            label="User email"
                                            source="userId"
                                            reference="CognitoUsers">
                                            <TextField source="email" />
                                        </ReferenceField>
                                    )
                                case 'description':
                                    return (<FunctionField label={'Description'} value={(val) => getDescriptionForEvent(val)} />)
                                case 'dataBefore':
                                case 'dataAfter':
                                case 'dataDiff':
                                    return (
                                        <BQJsonField label={visibleFields[field].label} source={field} />
                                    )
                                default:
                                    return (<TextField source={field} label={visibleFields[field].label} style={{ fontSize: '14px' }} />)
                            }
                        })}
                    </Datagrid>
                </BQSection >
            </>
        </List >
    )
}

const BQAuditTrailFilter = (props) => {
    const notify = useNotify()
    const filterItem = useInput(props)
    const { onChange, value } = filterItem?.field
    const currentState = value && JSON.parse(value) || {}
    const [filterObject, setFilterObject] = useState(currentState)
    const classes = getFromCache('bqClasses')


    const { collections } = props

    const search = () => {
        const filterQuery = {}
        const filterObjectKeys = Object.keys(filterObject)
        if (filterObjectKeys.length) {
            filterObjectKeys.map(key => {
                if (filterObject[key]?.toString()?.trim()) {
                    switch (key) {
                        case 'from':
                        case 'to':
                            filterQuery[key] = new Date(filterObject[key]?.toString().trim()).getTime()
                            break;
                        default:
                            filterQuery[key] = filterObject[key]?.toString()?.trim()
                            break;
                    }
                }
            })
            if (filterObject["from"] > (filterObject["to"] || Number.MAX_VALUE)) {
                notify('Date range is wrong. "From" value should be lower than "To" value', { type: 'warning' })
            }
            else if (!filterObjectKeys.filter(key => key !== 'from' && key !== 'to' && key !== 'refresh' && filterObject[key]?.toString()?.trim())?.length) {
                notify('Please use at least one filter (other than a time range)', { type: 'warning' })
            } else {
                filterQuery.refresh = createRefreshToken()
                onChange(JSON.stringify(filterQuery))
            }
        }
    }

    useEffect(() => {
        clearSearch()
    }, [])

    const clearSearch = () => {
        setFilterObject({})
        onChange(JSON.stringify({ refresh: createRefreshToken() }))
        setTimeout(() => {
            const inputs = document.body.getElementsByTagName('input')
            for (const index in inputs) {
                const inputItem = inputs[index]
                if (inputItem.type === 'text' || inputItem.type === 'datetime-local') {
                    inputItem.value = ''
                }
            }
        }, 0)
    }

    return <div>
        <span style={{ lineHeight: '1.5em' }}>{`Generated at: ${getDateTime(new Date())}`}<br />{`By: ${bqAuthData.name} (${bqAuthData.email})`}</span>
        <table style={{ width: '100%', tableLayout: 'fixed' }}>
            <tr>
                <td>
                    <BQSection title="Filters" style={{ maxWidth: '100%' }}>
                        <BQSideBySide>
                            <table className={classes.BQSectionContent} title={'Filter'}>
                                <BQFilterInput placeholder=' ' autoComplete={null} filterObject={filterObject} source="from" type="datetime-local" />
                                <BQFilterInput placeholder=' ' autoComplete={null} filterObject={filterObject} setFilterObject={setFilterObject} source="itemId" />
                                <BQFilterInput placeholder=' ' autoComplete={null} filterObject={filterObject} setFilterObject={setFilterObject} source="userId" />
                                <BQFilterInput placeholder=' ' autoComplete={null} filterObject={filterObject} source="userName" label="User email" />
                            </table>
                            <table className={classes.BQSectionContent} title={'Filter'}>
                                <BQFilterInput placeholder=' ' autoComplete={null} filterObject={filterObject} source="to" type="datetime-local" />
                                <BQFilterDropDown placeholder=' ' autoComplete={null} filterObject={filterObject} source="eventName" items={collections?.eventNames} defaultText="All Events" />
                                <BQFilterInput placeholder=' ' autoComplete={null} filterObject={filterObject} source="tableName" />
                            </table>
                        </BQSideBySide>
                    </BQSection>
                </td>
            </tr>

            <tr>
                <td style={{ margin: 'auto', textAlign: 'center' }}>
                    <SaveButton
                        id="btSearch"
                        alwaysEnable={true}
                        style={{
                            boxShadow: 'none'
                        }} label="Search" onClick={() => search()}
                    />
                    <Button
                        id="btClear"
                        type="reset"
                        onClick={clearSearch}>Clear</Button>
                </td>
            </tr>
        </table>
    </div>
}


export default BQCRUDBuilder({
    Create: null,
    Edit: null,
    List: AuditTrailList,
})