import * as React from 'react';
import { Button, Create, Edit, useRecordContext, useRedirect } from "react-admin"
import BQCRUDBuilder from "../Generic/BQCRUDComponent"
import { BQDropDown, BQInput } from "../Generic/bq-input"
import { ShipmentFormContainer, SinglePackageSection } from "./inventoryUI"
import { bqAuthData } from '../../utils/bq-auth';
import { BQSection } from '../Generic/BQUI';
import { getDateTime } from '../../utils/textUtils';
import { validate } from '../../utils/validations';
import { BQCheckbox, CustomList, DefaultValueField } from '../Generic/bq-form-components';
import { editorGlobalProps } from '../../utils/constants';
import { prepareFormData } from '../../utils/transforms';
import { useBQGetList } from '../Generic/hooks';
import { useGetAppSettings } from '../../utils/globals';
import { filterItemsForCleaning, prepareItemInPackage } from '../../utils/inventoryTools';

export const CleaningList = () => {
    const listData = useBQGetList({ resource: 'cleanings' })
    const redirect = useRedirect()

    return <CustomList {...{
        title: 'Cleanings',
        maxHeight: 512,
        data: listData?.data,
        sortField: 'updatedAt',
        sortOrder: 'ASC',
        rowClick: (e) => redirect(`/cleanings/${e}`),
        component: <Button onClick={() => redirect(`/cleanings/create`)}>Cleaning Form</Button>
    }}>
        <DefaultValueField source="createdAt" label="Cleaning date" value={v => v && getDateTime(new Date(v), true)} />
        <DefaultValueField source="updatedAt" label="Identifier" value={v => v && new Date(v).getTime()} />
    </CustomList>
}

const CleaningEditor = () => {
    const { cleaningMaterial, itemTypes, itemStatuses } = useGetAppSettings()
    const record = useRecordContext() || {}
    const [formWatch, setFormWatch] = React.useState({})
    const { isSuperAdmin } = bqAuthData
    const readOnly = false

    const cleaningDate = formWatch[2]


    return !!itemStatuses && <ShipmentFormContainer {...{
        id: record?.id,
        formType: 'cleaning',
        setFormWatch: null,
        fieldsToWatch: [],
        label: 'Cleaning Form'
    }}>
        <BQSection title="Cleaning Info" style={{ minWidth: '100%' }}>
            {/* <BQInput source="returnDate" label="Return date" type="date" readOnly={!isSender} /> */}
            <BQInput source="cleaningDate" label="Date cleaned" type="date" readOnly={readOnly} />
            <BQDropDown source="cleaningMaterial" choices={cleaningMaterial} unsorted validate={cleaningDate ? validate : undefined} allowFreeText readOnly={readOnly} />
            <BQInput source="cleaningMaterialExpirationDate" label="Material expiration date" type="date" readOnly={readOnly} />
            <BQInput source="comments" multiline readOnly={readOnly} />
        </BQSection>
        <SinglePackageSection {... {
            source: 'package',
            pack: record.package,
            packageStatusFilter: itemStatuses.USED,
            canEditList: true,
            canEditCustomChildren: true,
            includeItems: filterItemsForCleaning
        }} >
            <BQCheckbox source="cleaned" width={86} readOnly={false} />
        </SinglePackageSection>
    </ShipmentFormContainer>
}

const cleaningTransform = async (data) => {
    Object.keys(data).filter(key => key.toLowerCase().indexOf('date') !== -1).forEach(key => {
        if (data[key]) {
            data[key] = new Date(data[key]).getTime()
        }
    })
    data.package.items = data.package?.items?.map((item, index) => prepareItemInPackage(item, index))
    return prepareFormData(data)
}

const CleaningMutation = (isCreate, props) => {
    const mutationProps = { ...props, ...editorGlobalProps(), transform: (data) => cleaningTransform(data) }
    return (
        <div>
            {isCreate ?
                (<Create {...mutationProps}>
                    <CleaningEditor {...props} />
                </Create>)
                :
                (<Edit {...mutationProps}>
                    <CleaningEditor {...props} />
                </Edit>)
            }
        </div>
    )
}

export const CleaningCreate = (props) => CleaningMutation(true, props)

export const CleaningEdit = (props) => CleaningMutation(false, props)

export default BQCRUDBuilder({
    Create: CleaningCreate,
    Edit: CleaningEdit,
    List: CleaningList,
})