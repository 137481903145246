import * as React from 'react';
import { Button, useNotify, useRecordContext, useRedirect } from "react-admin"
import { BQCheckboxInput, CustomList, DefaultValueField } from "../Generic/bq-form-components"
import { BQSection, BQSideBySide } from "../Generic/BQUI"
import { BQInput } from "../Generic/bq-input"
import { validateDate } from "../../utils/validations"
import { OUSelectInput } from "../organizationUnitSelector.component"
import { getDateTime } from "../../utils/textUtils"
import { bqAuthData } from '../../utils/bq-auth';
import { prepareFormData } from '../../utils/transforms';
import { editorGlobalProps } from '../../utils/constants';
import { Create, Edit } from 'react-admin';
import { getFromCache, useGetAppSettings } from '../../utils/globals';
import { useBQGetList, useBQParams } from '../Generic/hooks';

import { PackageListSection, ShipmentFormContainer, onItemStateChanged } from './inventoryUI';
import BQCRUDBuilder from '../Generic/BQCRUDComponent';
import { highlightObject_cleaning, prepareItemInPackage } from '../../utils/inventoryTools';


export const ShipmentList = (props) => {
    const { isSuperAdmin, isInventoryAdmin, ou } = bqAuthData
    const redirect = useRedirect()
    const classes = getFromCache('bqClasses');
    const { shipmentStatuses, shipmentTypes } = useGetAppSettings()
    const [showDeliveredShipments, setShowDeliveredShipments] = React.useState(true)
    return !!shipmentStatuses
        ?
        <>
            {(isSuperAdmin || isInventoryAdmin || ou === 'BrainQ')
                ?
                <ShipmentListContainer
                    title='All Shipments'
                    showDeliveredShipments={showDeliveredShipments}
                    initialSort='shipmentDate'
                    component={
                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '10px' }}>
                            <BQCheckboxInput label="Show completed shipments" value={showDeliveredShipments} onChange={() => setShowDeliveredShipments(!showDeliveredShipments)} />
                            <Button onClick={() => redirect(`/shipments/create?shipmentType=${shipmentTypes.TRANSACTION}`)}>Create Shipment</Button>
                        </div>
                    }
                >
                    <DefaultValueField source="shipmentDate" label="Shipped At" value={(v => getDateTime(v, true))} />
                    <DefaultValueField label="Tracking Number / Identifier" value={(v, record) => record.trackingNumber || new Date(record.createdAt).getTime()} />
                    <DefaultValueField source="senderOU" label="Origin" />
                    <DefaultValueField source="destinationOU" label="Destination" />
                    <DefaultValueField source="deliveryDate" value={v => v ? getDateTime(v, true) : null} />
                    <DefaultValueField source="status" value={v => shipmentStatuses?.[v].name || v} />
                    <DefaultValueField value={(v, record) => record.status === shipmentStatuses.IN_TRANSIT.id && <div className={classes.packageReceived} title={'Confirm received'}></div>} />
                </ShipmentListContainer>
                :
                <>
                    <ShipmentListContainer
                        listType={'INCOMING_ITEMS'}
                        title='Incoming shipments'
                        showDeliveredShipments={showDeliveredShipments}
                        initialSort='shipmentDate'
                    >
                        <DefaultValueField source="shipmentDate" label="Shipped At" value={(v => getDateTime(v, true))} />
                        <DefaultValueField label="Tracking Number / Identifier" value={(v, record) => record.trackingNumber || new Date(record.createdAt).getTime()} />
                        <DefaultValueField source="senderOU" label="Origin" />
                        <DefaultValueField source="destinationOU" label="Destination" />
                        <DefaultValueField source="deliveryDate" value={v => v ? getDateTime(v, true) : null} />
                        <DefaultValueField source="status" value={v => shipmentStatuses?.[v].name || v} />
                        <DefaultValueField value={(v, record) => record.status === shipmentStatuses.IN_TRANSIT.id && <div className={classes.packageReceived} title={'Confirm received'}></div>} />
                    </ShipmentListContainer>

                    <ShipmentListContainer
                        listType={'OUTGOING_ITEMS'}
                        title='Outgoing shipments'
                        showDeliveredShipments={showDeliveredShipments}
                        initialSort='shipmentDate'
                        component={<Button onClick={() => redirect(`/shipments/create?shipmentType=${shipmentTypes.BACK_TO_WAREHOUSE}`)}>Send Back to BrainQ</Button>}
                    >
                        <DefaultValueField source="shipmentDate" label="Shipped At" value={(v => getDateTime(v, true))} />
                        <DefaultValueField label="Tracking Number / Identifier" value={(v, record) => record.trackingNumber || new Date(record.createdAt).getTime()} />
                        <DefaultValueField source="senderOU" label="Origin" />
                        <DefaultValueField source="destinationOU" label="Destination" />
                        <DefaultValueField source="deliveryDate" value={v => v ? getDateTime(v, true) : null} />
                        <DefaultValueField source="status" value={v => shipmentStatuses?.[v].name || v} />
                    </ShipmentListContainer>
                </>
            }</>
        :
        <></>
}

const ShipmentListContainer = ({ listType, title, showDeliveredShipments, initialSort, children, ...rest }) => {
    const redirect = useRedirect()
    const notify = useNotify()
    const { shipmentStatuses } = useGetAppSettings()
    const listData = useBQGetList({ resource: 'shipments', meta: { listType: listType || 'INCOMING_ITEMS', showDeliveredShipments } })
    return <CustomList
        {...{
            title,
            maxHeight: 512,
            data: listData?.data,
            sortField: initialSort,
            sortOrder: 'DESC',
            rowClick: (id, resource, record) => {
                listType === 'INCOMING_ITEMS' && record.status === shipmentStatuses.READY_TO_SHIP.id ? notify('Shipment is pending, Wait for it to be shipped.') : redirect(`/shipments/${id}`)
            },
            ...rest
        }}>
        {children}
    </CustomList>
}

const fieldsToWatch = []

const ShipmentEditor = () => {
    const { isSuperAdmin, isInventoryAdmin } = bqAuthData
    const isSuperUser = isSuperAdmin || isInventoryAdmin
    const { shipmentTypes, containerTypes, itemStatuses, shipmentStatuses } = useGetAppSettings()
    const { shipmentType } = useBQParams()
    const record = useRecordContext() || {}
    const [formWatch, setFormWatch] = React.useState({})
    const isSender = bqAuthData.ou.toLowerCase() === record?.senderOU?.toLowerCase() || !record?.id

    const readOnly = record.deliveryDate && !isSuperAdmin
    const canChangePackage = !record.id

    const isBackToBQ = shipmentTypes && (shipmentType === shipmentTypes.BACK_TO_WAREHOUSE || record?.shipmentType === shipmentTypes.BACK_TO_WAREHOUSE)

    return !!shipmentTypes && <ShipmentFormContainer {...{
        id: record?.id,
        formType: 'shipment',
        setFormWatch,
        fieldsToWatch,
        shipmentType,
        showSendButton: !record?.status || record?.status === shipmentStatuses.READY_TO_SHIP.id,
        label: `${record.id ? `Shipment #${record.trackingNumber || new Date(record.createdAt).getTime()}` : `New Shipment`}`,
        readOnly: readOnly
    }}>
        <BQSideBySide>
            <BQSection title="Shipment Info" style={{ minWidth: '100%' }}>
                {isSuperUser && <OUSelectInput source="senderOU" label="Sent from" useNameAsValue readOnly={(!isSender || readOnly) && !isSuperUser} defaultValue={record?.id ? record?.senderOU : bqAuthData.ou} />}
                {!isBackToBQ && <OUSelectInput source="destinationOU" label="Destination" useNameAsValue readOnly={(!isSender || readOnly) && !isSuperUser} />}
                <BQInput source="shipmentDate" type="date" validate={validateDate} readOnly={!isSender || readOnly} />
                <BQInput source="trackingNumber" readOnly={(!isSender || readOnly) && !isSuperUser} />
                <BQInput source="shipmentComments" multiline readOnly={(!isSender || readOnly) && !isSuperUser} />
            </BQSection>
            {record.id && (record.deliveryDate || !isSender) ? <BQSection title="Receive status" style={{ minWidth: '100%' }}>
                <BQInput source="deliveryDate" type="date" validate={isSuperUser ? undefined : validateDate} readOnly={isSender || readOnly} label="Delivery date" />
                <BQInput source="deliveryComments" multiline readOnly={isSender || readOnly} />
            </BQSection> : undefined}
        </BQSideBySide>

        <PackageListSection {... {
            labelAdd: 'Add Package',
            isSender,
            record: record ?? { packages: [] },
            formWatch,
            canEditPackageCondition: (!isSender && !record.deliveryDate) || (record?.id && isSuperUser),
            packageConditionVisible: record?.id && !isSender,
            canEditItems: isSender,
            canEditList: canChangePackage,
            containerType: containerTypes.SHIPMENT,
            actionType: shipmentType,
            packageStatusFilter: (!isSuperAdmin && !isInventoryAdmin) && shipmentType === shipmentTypes.BACK_TO_WAREHOUSE
                ? (pkg) => pkg.status != itemStatuses.IN_USE && pkg.status != itemStatuses.IN_TRANSIT
                : undefined,
            canEditItemStatus: shipmentType === shipmentTypes.BACK_TO_WAREHOUSE && (isSender || isSuperUser),
            itemStatusVisible: shipmentType === shipmentTypes.BACK_TO_WAREHOUSE,
            highlightItems: shipmentType === shipmentTypes.BACK_TO_WAREHOUSE ? highlightObject_cleaning : undefined
        }} />
    </ShipmentFormContainer>
}



const shipmentTransform = async (data) => {
    delete data.status
    delete data.senderUserId
    delete data.receiverUserId
    data.destinationOU = data.destinationOU || 'BrainQ'
    data.shipmentDate = new Date(data.shipmentDate).getTime()
    if (data.deliveryDate) {
        data.deliveryDate = new Date(data.deliveryDate).getTime()
    }
    data.packages = data.packages?.map(p => {
        return {
            ...p,
            items: p.items.map((item, index) => prepareItemInPackage(item, index))
        }
    })

    data = prepareFormData(data)
    return data
}

const ShipmentMutation = (isCreate, props) => {
    const mutationProps = { ...props, ...editorGlobalProps(), transform: (data) => shipmentTransform(data) }
    return (
        <div>
            {isCreate ?
                (<Create {...mutationProps} >
                    <ShipmentEditor {...props} />
                </Create>)
                :
                (<Edit {...mutationProps}>
                    <ShipmentEditor {...props} />
                </Edit>)
            }
        </div>
    )
}

const ShipmentCreate = (props) => ShipmentMutation(true, props)

const ShipmentEdit = (props) => ShipmentMutation(false, props)



export default BQCRUDBuilder({
    Create: ShipmentCreate,
    Edit: ShipmentEdit
})